import React, { useState, useEffect } from "react"
import "../../components/layout.css"
import Iframe from "react-iframe"
import Seo from "../../components/seo"
import axios from "axios"
import SelectPopup from "../../components/SelectPopup"
import Select from "react-select"
import {selectOptions, selectTitle} from "../../utils/options"
import PopupContainer from "../../components/popupContainer";
import Uploader from "../../components/uploader";
//import * as queryString from "query-string"

const PrsgCHPage = () => {
    const name = "Camarero"
    const feedback = "Sí"

    const popUpTheme = '#7E8FB6'
    const [selection, setSelection] = useState([])
    const [variableName, setVariableName] = useState("")
    const [visibility, setVisibility] = useState(false)
    const [regionSelected, setRegionSelected] = useState("")
    const [loading, setLoading] = useState(true)
    const [session, setSession] = useState("no sesion")

    const [uploadType, setUploadType] = useState("");
    const [clientId, setClientId] = useState("");
    const [uploadContainerVisibility, setUploadContainerVisibility] = useState(false);
    const [ modalUploaderTitle, setModalUploaderTitle ] = useState('Sube tu archivo');

    const headers = {
        Authorization: "bearer 40b3ff5fdeaf4ca6851eecadd6eec23c",
    }

    useEffect(() => {
        getBotId()
        eventSubscribe()
    }, [])

    const getBotId = async () => {
        try {
            const getBotId = await axios({
                method: "get", //you can set what request you want to be
                url:
                    "https://api.33bot.io/v1/conversation/chat/635fa849dd71940009aae6d3/bots",
                headers,
            })
            //const bot_id = getBotId.data[0].id

            const newSession = await axios({
                method: "post", //you can set what request you want to be
                url: "https://api.33bot.io/v1/conversation/create",
                data: {
                    bot_id: getBotId.data[0].id,
                },
                headers,
            })

            const someSession = newSession.data.id
            setSession(someSession)

            const updateSession = await axios({
                method: "post", //you can set what request you want to be
                url: "https://api.33bot.io/v1/conversation/update",
                data: {
                    session_id: someSession,
                    global_vars: {
                        candidatura_seleccionada: {
                            text: name,
                            value: name,
                        },
                        feedback: {
                            text: feedback,
                            value: feedback,
                        },
                    },
                },
                headers,
            })

            if (updateSession.data.status === "ok") {
                setLoading(false)
            }
        } catch (error) {
            console.log(error)
        }
        console.log(`iniciando chatbot con sesión ${session}`)
    }

    const updateUploadData = async (uploadedType, uploadedPath) => {
        let dataVars = {};
        dataVars[uploadedType] = {
            text: uploadedPath,
            value: uploadedPath,
        };

        await axios({
            method: "post", //you can set what request you want to be
            url: "https://api.33bot.io/v1/conversation/update",
            data: {
                session_id: session,
                global_vars: dataVars,
            },
            headers,
        })

        let responseMessage = 'El archivo se ha subido correctamente';

        await axios({
            method: "post", //you can set what request you want to be
            url: "https://api.33bot.io/v1/conversation/message/user",
            data: {
                session_id: session,
                text: responseMessage,
            },
            headers,
        })
    }

    let replyAsUser = async( message ) => {

        await axios({
            method: "post", //you can set what request you want to be
            url: "https://api.33bot.io/v1/conversation/message/user",
            data: {
                session_id: session,
                text: message,
            },
            headers,
        })
    }

    let uploadPopupCloseHandler = (uploadedType, uploadedPath) => {
        updateUploadData(uploadedType, uploadedPath)
        setUploadContainerVisibility(false)
    }

    let closeUploadPopupHandler = () => {
        setUploadContainerVisibility( false );
        replyAsUser("Volver");
    }


    const updateData = async () => {

        const valueData = selection.map(sel => sel.value)

        let dataVars = {};
        dataVars[variableName] = {
            text: valueData.join(', '),
            value: valueData.join(', ')
        };

        await axios({
            method: "post", //you can set what request you want to be
            url: "https://api.33bot.io/v1/conversation/update",
            data: {
                session_id: session,
                global_vars: dataVars,
            },
            headers,
        })

        const labelData = selection.map(sel => sel.label)

        await axios({
            method: "post", //you can set what request you want to be
            url: "https://api.33bot.io/v1/conversation/message/user",
            data: {
                session_id: session,
                text: labelData.join(', '),
            },
            headers,
        })
    }

    let popupCloseHandler = () => {
        updateData()
        setVisibility(false)
        setSelection([])
    }

    let changeHandler = value => {
        if (value[0]) {
            setSelection([...value])
        } else {
            setSelection([value])
        }
        if (variableName === 'region') {
            setRegionSelected(value.value);

        }
    }

    const eventSubscribe = () => {
        window.addEventListener(
            "message",
            function (event) {
                const data = event.data
                if (event.data.event) {
                    switch (data.event) {
                        case "clearStorage":
                            localStorage.removeItem("session")
                            break
                        case "select":
                            setVariableName(data.variable)
                            setVisibility(true);
                            break;
                        case "upload":
                            setModalUploaderTitle(data.modalUploaderTitle)
                            setUploadType(data.variable)
                            setClientId(data.client_id)
                            setUploadContainerVisibility(true)
                            break;
                        default:
                            console.log(data)
                    }
                }
            },
            false
        )
    }

    return (
        <>
            <Seo title="Unete a H10" />
            <div style={{ width: "100vw", height: "100vh" }}>
                {loading ? (
                    <div className="loader">Cargando...</div>
                ) : (
                    <Iframe
                        url={`https://chat.33bot.io/635fad8bdafd35000928e38a?r=web&close=0&session=${session}`}
                        width="100%"
                        height="100%"
                        allow="camera;microphone"
                        frameborder="0"
                    />
                )}
                <SelectPopup
                    onClose={popupCloseHandler}
                    show={visibility}
                    theme={popUpTheme}
                    title={selectTitle[variableName]}
                >
                    <Select
                        className="basic-single"
                        classNamePrefix="select"
                        isClearable={true}
                        isSearchable={true}
                        name={variableName}
                        value={selection}
                        options={variableName === 'localidad' ? selectOptions[variableName][regionSelected] : selectOptions[variableName]}
                        onChange={changeHandler}
                        placeholder={"Selecciona..."}
                    />
                </SelectPopup>

                <PopupContainer
                    onClose={closeUploadPopupHandler}
                    show={uploadContainerVisibility}
                    title={modalUploaderTitle}
                    showButton={false}
                >
                    <Uploader
                        uploadType={uploadType}
                        updateData={updateUploadData}
                        popupClose={uploadPopupCloseHandler}
                        popUpTheme={popUpTheme}
                        clientId={clientId}
                    />
                </PopupContainer>
            </div>
        </>
    )
}

export default PrsgCHPage
